import $ from 'jquery';
import 'select2'

$(document).ready(function() {

    var removeRow = function($remove){
	$remove.off("click");
	$remove.parent().parent().remove();
    }
    
    var createRow = function(){
	var $conflictDiv = $("#conflicts").first();
	$conflictDiv.append($("#conflict-row").html());
	var $newRow = $conflictDiv.children().last();
	var $selectBox = $newRow.find("select").first();
	$selectBox.select2({
	    placeholder: "Select the Instructor"
	});
	
        $selectBox.on('select2:select', function (e) {
            var email = $(e.params.data.element).data("email");
	    var $emailField = $newRow.find('[type="email"]').first();
	    if (email){
	    	$emailField.val(email).prop("disabled", true);
	    }
	    else $emailField.val("").prop("disabled", false);
        });
	
	var $remove = $newRow.find("button").first();
	$remove.click(function(e){
	    e.stopPropagation();
	    removeRow($remove);
	    return false;
	});
    };
    
    //so that there are at least two rows to start
    createRow();
    createRow();

    $("#add-row").click(function(e){
	e.stopPropagation();
	createRow();
	return false;
    });

});
